import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
} from "react-router-dom";
import App from "./App";
import Main from "./components/Main";
// import Detaljer from "./pages/Detaljer";
// import TilmeldingNyhedsbrev from "./pages/TilmeldingNyhedsbrev";
// import Events, {loader as eventloader} from "./pages/Events";
// import Camps from "./pages/Camps";
// import Login from "./pages/Login";
// import Mitprogram, {loader as mitprogramLoader} from "./pages/Mitprogram";
// import Billetter, {loader as billetloader}  from "./pages/Billetter";
// import Info from "./pages/Info";
// import Colorit from "./pages/Colorit";
// import Kultunaut from "./pages/Kultunaut";
// import Bestil from "./pages/Bestil";
// import Pladsen from "./pages/Pladsen";
// import Map from "./pages/Map";
// import Program, {loader as programloader} from "./pages/Program";
// import ProtectedRoute from "./components/ProtectedRoute";
// import Error from "./pages/Error";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />} >
                    {/* <Route path="/" element={<App />} errorElement={<Error />}> */}
            <Route index element={<Main />} />
            <Route path="/Main" element={<Main />} />
            {/* <Route path="/Detaljer/:id" element={<Detaljer />} />
            <Route path="/TilmeldingNyhedsbrev" element={<TilmeldingNyhedsbrev />} />
            <Route path="/bands" element={<Events />} loader={eventloader} />
            <Route path="/Camps" element={<Camps />} />
            <Route path="/login" element={<Login />} />
            <Route path="/mitprogram" element={
                <ProtectedRoute>
            <Mitprogram />
                </ProtectedRoute>
            } loader={mitprogramLoader} />
            <Route path="/billetter" element={<Billetter />} loader={billetloader} />
            <Route path="/info" element={<Info />} />
            <Route path="/colorit" element={<Colorit />} />
            <Route path="/Kultunaut" element={<Kultunaut />} />
            <Route path="/info" element={<Info />} />
            <Route path="/Bestil" element={<Bestil />} />
            <Route path="/Pladsen" element={<Pladsen />} />
            <Route path="/map" element={<Map />} /> 
            <Route path="/Program" element={<Program />}  loader={programloader} />             */}
        </Route>
    )
);
