import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import Hero3 from "../images/Hero3.png";

const Main = () => {
    // const [nyheder, setNyheder] = useState();
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState();
    // useEffect(() => {
    //     fetch(`http://localhost:4000/news`)
    //         .then((response) => response.json())
    //         //   .then((data) => console.log(data))
    //         .then((data) => setNyheder(data))
    //         .catch(() => setError("Something went wrong"))
    //         .finally(() => setLoading(false));
    // }, []);
    // // return (
    // return loading ? (
    //     <p>loading...</p>
    // ) : (
    //     <>
    //         <div className="containerBody">
    //             <img className="cover" src={Hero3} alt="" />
    //             <section className="containerContent">
    //                 <div className="borderTopBund">
    //                 <h1>NYHEDER</h1>
    //                 </div>
    //                 <div className="flexWrap">
    //                     {nyheder.map((data) => (
    //                         <article
    //                             className="nyhederArticle"
    //                             key={data.id}
    //                         >
    //                                 <img
    //                                     className="coverNyheder"
    //                                     src={data.image.src}
    //                                     alt={data.image.title}
    //                                 />
    //                                 <h3 className="margin10">{data.title}</h3>
    //                                 <p className="margin10">{data.body[0]}</p>
    //                             <Link className="" to={`/Detaljer/${data.id}`}>
    //                                 <p className="btnBlack margin10">
    //                                     LÆS MERE
    //                                 </p>
    //                             </Link>
    //                         </article>
    //                     ))}
    //                 </div>
    //             </section>
    //         </div>
    //     </>
    // );
};

export default Main;
