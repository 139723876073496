import React from "react";
import { Link } from "react-router-dom";
// import logo from "../images/Logo.png";
// import Nav from "./Nav";

const Header = () => {
    return (
        <header className="backgroundHeader">
            <div className="linkContainer">
                <Link className="App-link logo" to="/">
                    KALBERS
                </Link>
                <Link className="App-link" to="/">
                    HOME
                </Link>
                <Link className="App-link" to="/">
                    SPIL
                </Link>
                <Link className="App-link" to="/">
                    HOME
                </Link>
                <Link className="App-link" to="/">
                    SPIL
                </Link>
            </div>
            <div className="loginContainer">
                <Link className="App-link btnNavSearch" to="/">
                    Søg
                </Link>
                <Link className="App-link btnNavSignin" to="/">
                    Login
                </Link>
                <Link className="App-link btnNavSignin" to="/">
                    Opret
                </Link>
            </div>
        </header>
    );
};

export default Header;
