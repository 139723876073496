import logo from "./logo.jpg";
import "./App.css";
import Header from "./components/Header";
import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";

function App() {
    return (
        <div className="App">
            <Header />

            <main className="backgroundMain">
                <p>Kristine Albers</p>
                <img src={logo} className="App-logo" alt="logo" />
                <p>Webudvikler</p>
            </main>
            <footer className="backgroundFooter">
                <p>Store Valbyvej 247C</p>
                <p>4000 Roskilde</p>
            </footer>
        </div>
    );
}

export default App;
